import React from "react";
import FillForm from '../components/fill-form/fillForm.js';
import Layout from "../components/layout";
import PageTranslator from '../components/PageTranslator';



const FillFormPage = () => {
    return (
        <>
        <Layout>
        <PageTranslator />
            <FillForm/>
        </Layout>
        </>
    );
};

export default FillFormPage;
