import React, { useState } from 'react';
import '../styles/Homepage.css';
import NavBar from '../components/homeNavBar';
import IntroSection from '../components/introSection';
import ServicesSection from '../components/servicesSection';
import FindVGSection from '../components/findVG';
import TeamSection from '../components/teamSection';
import BusinessSection from '../components/businessSection';
import Footer from '../components/Footer';
import FeedbackSection from '../components/feedbackSection';
import TeamMembersSection from '../components/teamMembersSection';
import PageTranslator from '../components/PageTranslator';

const VaccineGenieHomePage = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>

      <PageTranslator />
      
      <NavBar/>
      <IntroSection/>
      <ServicesSection/>
      <TeamSection/>
      <FindVGSection/>
      <BusinessSection/>
      <TeamMembersSection/>
      <Footer/>
      <div id="google_translate_element"></div>




      
      
    </>
  );
};

export default VaccineGenieHomePage;