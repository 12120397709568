import React from 'react';
import '../styles/instructionsPopUp.css';

const TermsPopUp = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('popup-overlay')) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content" style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '4rem' }}>
        <button className="close-button" onClick={onClose}>
          x
        </button>
        <h2 style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
          Agreement for Vaccine Record Digitization System
        </h2>
        <div style={{ fontSize: '0.9rem', color: '#333' }}>
          <p><strong>1. General Terms</strong><br />
          This Agreement outlines the terms and conditions for the digitization and translation of vaccination records. By utilizing this service, the customer acknowledges and agrees to the following terms.</p>

          <p><strong>2. Translation Scope and Limitations</strong><br />
          <strong>Translation Time:</strong> Translation time may vary depending on the complexity of the record, language pair, and volume of entries. Rush services may be available for an additional fee.<br />
          <strong>Recognized Entries:</strong> Only recognized vaccine entries will be translated. Ambiguous, incomplete, or unrecognized entries may be excluded.<br />
          <strong>Customer Responsibility:</strong> Customers are responsible for ensuring the accuracy of their submitted records. Unrecognized or ambiguous entries must be clarified or indicated by the submitter to avoid additional costs or delays. Vaccine Genie is not responsible for translating ambiguous, incomplete, or unrecognized entries.<br />
          <strong>Translation Limits:</strong> The translation is limited to the vaccine name, date of administration, lot information if available, and other critical details if required by the destination country or institution and specified by the customer.</p>

          <p><strong>3. Application & Submission Process</strong><br />
          <strong>Submission Method:</strong> Customers must submit their records via the provided online form. Accepted formats include only those noted on the online form.<br />
          <strong>Language Support:</strong> The service currently supports Chinese to/from English translation. Additional languages may be available upon request, subject to availability and additional fees.<br />
          <strong>Required Information:</strong> Customers must include their full name, date of birth, and contact information in the submission form to ensure accurate record matching and processing.</p>

          <p><strong>4. Pricing & Cost Adjustments</strong><br />
          <strong>Cost Determination:</strong> Translation costs will be determined based on the number of recognized and translatable vaccine entries. A detailed breakdown of costs will be provided before finalizing the service.<br />
          <strong>Non-Translatable Entries:</strong> If an entry is deemed non-translatable, the cost for that entry will be deducted from the final pricing. Customers will be notified of any such deductions.<br />
          <strong>Additional Fees:</strong> Additional fees may apply for rush services.</p>

          <p><strong>5. Finalization and Approval</strong><br />
          <strong>Customer Review:</strong> The customer must review and confirm the sample translated records within 3 business days of receipt. Any discrepancies or disputes must be raised during this period.<br />
          <strong>Dispute Resolution:</strong> Disputes regarding translations must be submitted in writing, detailing the specific issues. The service provider will review and address disputes within 5 business days.<br />
          <strong>Finalization:</strong> Once approved, the digitized record will be finalized, and no further modifications will be made. A digital copy of the finalized record will be provided to the customer.</p>

          <p><strong>6. Agreement Acknowledgment</strong><br />
          By submitting vaccination records for digitization, the customer acknowledges and agrees to the terms stated in this agreement. The customer also confirms that they have provided accurate and complete information and understand the limitations and responsibilities outlined herein.</p>

          <p><strong>7. Confidentiality and Data Security</strong><br />
          <strong>Data Protection:</strong> All submitted records will be handled with strict confidentiality and stored securely in compliance with data protection regulations. Submitted records will be deleted from the system 30 days after finalization unless otherwise requested by the customer.<br />
          <strong>Third-Party Access:</strong> No third parties will have access to the customer’s records without explicit consent, except as required by law.</p>

          <p><strong>8. Amendments to the Agreement</strong><br />
          <strong>Updates:</strong> The service provider reserves the right to update or amend this agreement at any time. Customers will be notified of significant changes via email or through the service platform.<br />
          <strong>Continued Use:</strong> Continued use of the service after amendments constitutes acceptance of the updated terms.</p>

          <p><strong>9. Dispute Resolution and Applicable Law</strong><br />
          The parties agree that all disputes, claims and controversies arising out of this Agreement shall be settled by arbitration in accordance with the American Arbitration Association rules, such arbitration to take place in the State of Washington, and judgment upon the award rendered in any such arbitration may be entered in any court, state or federal, having jurisdiction.<br />
          This Agreement and any dispute or controversy arising out of or relating to this Agreement shall, in all respects, be governed by and construed according to the laws of the State of Washington, without regard to its conflict of law principles.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsPopUp;
