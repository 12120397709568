import React, { useState } from "react";
import Team from '../components/teamMembers';
import Layout from "../components/layout";
import PageTranslator from '../components/PageTranslator';




const TeamsPage = () => {
    return (
        <Layout>
            <PageTranslator />
            <Team />
        </Layout>
    );
};

export default TeamsPage;