// Local:
// const BASE_URL = "http://127.0.0.1:5001/vaccine-genie/us-central1";
// Remote:
const BASE_URL = "https://us-central1-vaccine-genie.cloudfunctions.net";
const PATIENT_TEMP_ID = "36a0c0cf-7316-47ba-80ab-ce2acdc3ba10";

function mappingData(vaccinesInfo) {
    /*
     vaccinesInfo:
        [
          {
            "vaccineName": "ABC",
            "vaccinationDates": [
              "2025-02-25",
              "2025-02-23"
            ]
          },
          {
            "vaccineName": "HPV",
            "vaccinationDates": [
              "2025-02-24"
            ]
          }
        ]
     patient payload:
        {
          "birthDate": "2005-04-12",
          "gender": "male",
          "name": {
            "family": "test",
            "given": [
              "temp-"
            ],
            "use": "official"
          },
          "resourceType": "Patient"
        }
     immu payload:
         {
          "patientId": "36a0c0cf-7316-47ba-80ab-ce2acdc3ba10",
          "vaccines": [
            {
              "vaccineCode": "08",
              "vaccineName": "Hepatitis B",
              "vaccinationEvents": [
                {
                  "batchNumber": "HEPB001",
                  "siteCode": "LT",
                  "site": "Left Thigh",
                  "adminName": "LT",
                  "date": "2023-11-20T00:00:00.000Z"
                }
              ]
            }
          ]
        }
    */
    // https://console.cloud.google.com/healthcare/fhirviewer/us-west1/vgsmartfhir/fhirStores/vg_fhir_datastore/browse/Patient?authuser=1&project=vaccine-genie
    console.log("Mapping table data to immunizations data...");
    let IMMU_TEMP = {
        "patientId": PATIENT_TEMP_ID,
        "vaccines": []
    };

    vaccinesInfo.forEach((v, i) => {
        let _v = {
            "vaccineCode": "vaccineCode-" + i,
            "vaccineName": v.vaccineName,
            "vaccinationEvents": []
        }
        v.vaccinationDates.forEach((date, j) => {
            let _e = {
                "batchNumber": v.vaccineName + j,
                // default values - must change
                "siteCode": "LT",
                "site": "Left Thigh",
                "adminName": "LD",
                "date": new Date(date).toISOString(),
            }
            _v["vaccinationEvents"].push(_e);
        });

        IMMU_TEMP["vaccines"].push(_v);
    });

    return IMMU_TEMP;
}

const addImmunizationsToTempPatient = async (vaccinesInfo) => {
    console.log("Adding immunizations to patient...");
    if (!vaccinesInfo) {
        console.log("Vaccines Infor is required");
        return;
    }
    const payload = mappingData(vaccinesInfo);
    console.log("Immunizations payload: " + JSON.stringify(payload));
    const res = await fetch(`${BASE_URL}/immunizations`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    });

    if (!res.ok) {
        throw new Error("Failed to send immunizations");
    }

    const result = await res.json();

    console.log("Adding immunizations result:", result);
    return result;
}

const sendFillFormRequest = async ({ url, payload }) => {
    try {
        console.log("Sending filling form request...");
        if (!url) {
            console.log("url is required");
            return;
        }
        if (!payload) {
            console.log("payload is required");
            return;
        }

        console.log("Filling form payload: " + JSON.stringify(payload));
        const res = await fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        })

        if (!res.ok) {
            throw new Error("Failed to send data for filling form endpoint");
        }

        const result = await res.blob();
        console.log("Filling form result:", result);
        return result;
    } catch (err) {
        console.log("Error while sending fill-form request", err);
    }
    return null;
}

const triggerFillFormLite = async ({ vaccines, formCode }) => {
    console.log("Filling form lite...");
    if (!vaccines || !formCode) {
        console.log("vaccines && formCode are required");
        return;
    }
    return sendFillFormRequest({
        url: `${BASE_URL}/fillingFormLite`,
        payload: { vaccines, formCode }
    })
}

const triggerFillForm = async ({ patientId, formCode }) => {
    console.log("Filling form for temporary patient...");
    patientId = PATIENT_TEMP_ID;
    if (!patientId || !formCode) {
        console.log("patientId && formCode are required");
        return;
    }
    return sendFillFormRequest({
        url: `${BASE_URL}/fillingForm`,
        payload: { patientId, formCode }
    })
}

const handler = {
    addImmunizationsToTempPatient,
    triggerFillForm,
    triggerFillFormLite,
}

export default handler;
