import {
    Button, DropdownButton, Dropdown
} from "react-bootstrap";
import { ReactComponent as Upload } from '../../icons/upload.svg';
import React, { useState, useEffect } from 'react';
import downloadjs from "downloadjs";

import styles from "./fillForm.module.css"
import handler from "./fillFormHandler.js";
import { useLocation } from "react-router-dom";

function FillForm() {

    const [pdfResult, setPdfResult] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isPreviewClicked, setIsPreviewClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');

    const location = useLocation();
    const [data, setData] = useState(location.state?.data || []);

    if (data.length > 0) {
        console.log("Received Data:", data);
    } else {
        console.log("No data for filling form");
    }

    const handleSelect = async (eventKey) => {
        setSelectedItem(eventKey);
        if (eventKey === "WA CIS") {
            try {
                if (data && data.length > 0) {
                    setIsLoading(true);
                    /*
                      Important Note:
                      
                      Currently, the website (frontend) does not have any feature related to patients and immunizations.
                      The "Filling Form" feature requires both patient information (specifically patientId) and immunization details to function correctly.

                      Without this information, the filling form cannot work as intended. 
                      To make it functional for now, we’ll temporarily add immunizations to a "temp" user.

                      All temporary patient names must start with *"temp-"* for easy identification and removal later, once patient and immunization features are implemented.
                      This is a temporary solution and should not be considered permanent.
                    */
                    // await handler.addImmunizationsToTempPatient(data);
                    /*
                     Update: 30/3/2025
                     We’re using a new endpoint to fill the form without adding patient details.
                    */
                    const _pdfResult = await handler.triggerFillFormLite({ vaccines: data, formCode: "CIS" });
                    setPdfResult(_pdfResult);
                }
            } catch (error) {
                console.log("Error while filling form...", error);
                setPdfResult(null);
                setPdfUrl(null);
                if (data && data.length > 0) {
                    setData([]);
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        // Cleanup: revoke object URL when the component unmounts or pdfUrl changes
        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);

    const handlePreviewClick = () => {
        setIsPreviewClicked(true);
        if (pdfResult) {
            const url = URL.createObjectURL(pdfResult);
            setPdfUrl(url);
        }
    };

    const handleDownloadClick = () => {
        if (pdfResult) {
            downloadjs(pdfResult, "document.pdf", "application/pdf");
        }
    }

    return (
        <div class={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.logoStyle}>
                    <Upload />
                </div>
                <div>
                    <span className={styles.headerTextStyle}>Filling form</span>
                </div>
            </div>

            <div className={styles.bodyStyle}>
                <div className={styles.buttonContainer}>
                    <DropdownButton className={`d-grid ${styles.buttonStyle}`} onSelect={handleSelect} title="Select form">
                        <Dropdown.Item eventKey="WA CIS">WA CIS</Dropdown.Item>
                    </DropdownButton>
                    <Button className={styles.buttonStyle} onClick={handlePreviewClick} variant="primary">Preview</Button>
                </div>
                {isLoading && (
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                )}
                <div className={styles.iframeContainer}>
                    <iframe className={styles.iframeStyle} src={pdfUrl && isPreviewClicked ? pdfUrl : ""} width="100%" height="600px" />
                </div>
                <div className={styles.buttonContainer}>
                    <Button style={{ width: "50%" }} onClick={handleDownloadClick} variant="primary">Download</Button>
                </div>
            </div>
        </div>
    );
}

export default FillForm;
