import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import HomePage from './pages/VaccineGenieHomepage';
import UploadPage from './pages/UploadPage';
import UploadPage2 from './pages/UploadPage2';
import TeamsPage from './pages/TeamsPage';
import FillFormPage from './pages/FillFormPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/upload' element={<UploadPage />} />
        <Route path='/upload2' element={<UploadPage2 />} />
        <Route path='/team' element={<TeamsPage />} />
        <Route path='/fill-form' element={<FillFormPage/>} />

      </Routes>
      
    
    </BrowserRouter>
  );
}

export default App;
