import React, { useState } from 'react';
import '../styles/Footer.css';
import { ReactComponent as Logo } from '../icons/Logo.svg';
import { Container, Row, Col } from 'react-bootstrap';
import { Facebook, Linkedin, Github } from 'lucide-react';
import TermsPopUp from './TermsPopUp'; // Adjust path as needed


const Footer = () => {
  const [showTerms, setShowTerms] = useState(false);

  return (
    <>
      <footer className="bg-light py-4 mt-5" style={{ paddingLeft: '0px' }}>
        <Container>
          <Row className="align-items-center">
            <Col md={4} className="mb-3 mb-md-0">
              <Logo />
              <p className="mt-3 text-muted">
                Welcome to our specialized platform dedicated to bridging language barriers in the realm of medical records – both handwritten and digital Vaccine Records.
              </p>
            </Col>
            <Col md={4} className="mb-3 mb-md-0">
              <h5>Quick Links</h5>
              <ul className="list-unstyled">
                <li style={{ display: 'none' }}><a href="#home">Home</a></li>
                <li style={{ display: 'none' }}><a href="#services">Services</a></li>
                <li style={{ display: 'none' }}><a href="#team">Team</a></li>
                <li style={{ display: 'none' }}><a href="#work">Work</a></li>
                <li><a href="mailto:info@vaccinegenie.com"><span className='link-text'>Contact - info@vaccinegenie.com</span></a></li>
                <li>
                  <span
                    onClick={() => setShowTerms(true)}
                    className='link-text'
                  >
                    Terms and Conditions
                  </span>
                </li>
              </ul>
            </Col>
            <Col md={4} style={{ display: 'none' }}>
              <h5>Connect With Us</h5>
              <div className="d-flex">
                <a href="#facebook" className="me-3 text-dark">
                  <Facebook size={24} />
                </a>
                <a href="#linkedin" className="me-3 text-dark">
                  <Linkedin size={24} />
                </a>
                <a href="#github" className="text-dark">
                  <Github size={24} />
                </a>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-center">
              <p className="text-muted mb-0">&copy; 2025 Vaccine Genie. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>

      <TermsPopUp isOpen={showTerms} onClose={() => setShowTerms(false)} />
    </>
  );
};

export default Footer;
